import { useState } from 'react';
import Logo from '@/components/ui-component/LogoClient';
import { analyticsTrack } from '@/util/analytics';
import { StandardModal } from '@magicschool/ui/Modal/StandardModal';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import type { ShareTheMagicResponse } from '@/app/api/shareTheMagic/route';
import { Box } from '@magicschool/ui/Box';
import { radius } from '@magicschool/ui/constants/radius';
import TwitterIcon from '@mui/icons-material/Twitter';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import { isValidEmail } from 'features/profile/utils';
type ShareTheMagicProps = {
  open: boolean;
  handleClose: () => void;
};
export default function ShareTheMagic({
  open,
  handleClose
}: ShareTheMagicProps) {
  const [emailFieldValue, setEmailFieldValue] = useState('');
  const intl = useIntl();
  const handleSubmit = async () => {
    const emails = emailFieldValue.split(',').map(e => e.trim());
    const invalidEmails = emails.filter(e => !isValidEmail(e));
    if (invalidEmails.length > 0) {
      toast.error(intl.formatMessage({
        id: 'share.the.magic.invalid.email'
      }) + invalidEmails.join(', '));
      return;
    }
    const response = await fetch<ShareTheMagicResponse>('/api/shareTheMagic', {
      method: 'POST',
      body: JSON.stringify({
        emails
      })
    });
    if (response.status === 200) {
      toast.success(intl.formatMessage({
        id: 'share.the.magic.email.sent'
      }));
      analyticsTrack('Share the Magic', {
        shareType: 'email',
        numEmails: emails.length
      });
    } else {
      toast.error(intl.formatMessage({
        id: 'share.the.magic.email.fail'
      }));
      analyticsTrack('Share the Magic: error', {
        shareType: 'email',
        status: response.status,
        statusText: response.statusText
      });
    }
    setEmailFieldValue('');
  };
  const shareOnTwitter = () => {
    const message = intl.formatMessage({
      id: 'share.the.magic.message'
    });
    const encodedMessage = encodeURIComponent(message);
    analyticsTrack('Share the Magic', {
      shareType: 'twitter'
    });
    window.open(`https://twitter.com/intent/tweet?text=${encodedMessage}`, '_blank');
  };
  return <StandardModal open={open} onClose={handleClose} scroll="paper" aria-labelledby="share-the-magic-modal" aria-describedby="share-magicschool" data-sentry-element="StandardModal" data-sentry-component="ShareTheMagic" data-sentry-source-file="ShareTheMagic.tsx">
      <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%'
    }} data-sentry-element="Box" data-sentry-source-file="ShareTheMagic.tsx">
        <Logo variant="free" width={150} data-sentry-element="Logo" data-sentry-source-file="ShareTheMagic.tsx" />
        <Typography variant="h4" component="span" sx={{
        fontWeight: 'bold',
        color: 'black',
        marginY: 3
      }} data-sentry-element="Typography" data-sentry-source-file="ShareTheMagic.tsx">
          <FormattedMessage id="share.the.magic.title" data-sentry-element="FormattedMessage" data-sentry-source-file="ShareTheMagic.tsx" />
        </Typography>
        <Button onClick={shareOnTwitter} variant="contained" size="medium" sx={theme => ({
        minWidth: 125,
        [theme.breakpoints.up('sm')]: {
          width: '100%'
        },
        [theme.breakpoints.up('md')]: {
          width: 200
        },
        mt: 2,
        height: '40px',
        borderRadius: radius.round,
        display: 'flex',
        alignItems: 'center',
        borderColor: '#5403ff',
        backgroundColor: 'transparent',
        color: 'black',
        ':hover': {
          color: '#4b02e5',
          backgroundColor: '#f6e5ff',
          borderColor: '#5403ff'
        }
      })} data-sentry-element="Button" data-sentry-source-file="ShareTheMagic.tsx">
          <TwitterIcon data-sentry-element="TwitterIcon" data-sentry-source-file="ShareTheMagic.tsx" />
          <Typography variant="h5" color={'inherit'} component="span" sx={{
          fontWeight: 'bold',
          marginLeft: 1
        }} data-sentry-element="Typography" data-sentry-source-file="ShareTheMagic.tsx">
            <FormattedMessage id="share.the.magic.twitter" data-sentry-element="FormattedMessage" data-sentry-source-file="ShareTheMagic.tsx"></FormattedMessage>
          </Typography>
        </Button>
      </Box>
      <Box sx={{
      display: 'flex',
      flexDirection: ['column', 'row'],
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 4,
      gap: 1
    }} data-sentry-element="Box" data-sentry-source-file="ShareTheMagic.tsx">
        <Input value={emailFieldValue} onChange={e => setEmailFieldValue(e.target.value)} name="emailInput" placeholder={intl.formatMessage({
        id: 'share.the.magic.email.placeholder'
      })} disableUnderline sx={{
        minWidth: 300,
        width: '100%',
        border: 1,
        paddingY: 1,
        paddingX: 2,
        borderRadius: radius[1.5],
        borderColor: '#5403ff'
      }} data-sentry-element="Input" data-sentry-source-file="ShareTheMagic.tsx" />
        <Button onClick={handleSubmit} variant="contained" size="medium" sx={{
        borderRadius: radius[1.5],
        borderColor: '#5403ff',
        backgroundColor: 'transparent',
        color: 'black',
        height: 'auto',
        ':hover': {
          color: '#4b02e5',
          backgroundColor: '#f6e5ff'
        }
      }} data-sentry-element="Button" data-sentry-source-file="ShareTheMagic.tsx">
          <Typography variant="body1" component="span" sx={{
          paddingY: '8.25px',
          marginX: 0.5,
          fontWeight: 'bold'
        }} data-sentry-element="Typography" data-sentry-source-file="ShareTheMagic.tsx">
            <FormattedMessage id="share.the.magic.send" data-sentry-element="FormattedMessage" data-sentry-source-file="ShareTheMagic.tsx"></FormattedMessage>
          </Typography>
        </Button>
      </Box>
    </StandardModal>;
}