import Link from '@/components/Link';
import { S_TOOLS_PATH, TOOLS_PATH } from '@/util/config';
// project imports

import Logo, { type LogoType } from '@/components/ui-component/LogoClient';
import MuiLink from '@mui/material/Link';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({
  variant
}: {
  variant?: LogoType;
}) => {
  return <MuiLink component={Link} href={variant === 'student' ? S_TOOLS_PATH : TOOLS_PATH} aria-label="magicschool-logo" data-sentry-element="MuiLink" data-sentry-component="LogoSection" data-sentry-source-file="index.tsx">
      <Logo variant={variant} data-sentry-element="Logo" data-sentry-source-file="index.tsx" />
    </MuiLink>;
};
export default LogoSection;