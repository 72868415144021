import { Main } from '@/components/styled/Main';
import { PageLoader } from '@magicschool/loader/PageLoader';
import { Box } from '@magicschool/ui/Box';
import { isProduction } from '@magicschool/utils/nextjs/metadata';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import type { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStore } from 'features/store';
import dynamic from 'next/dynamic';
import type { FC, ReactNode } from 'react';
import { USE_INTERCOM } from './EnvironmentSensitiveWrapperClient';
import Header from './Header';
import Sidebar from './Sidebar';
interface Props {
  children: ReactNode;
  bgOverride?: string;
  loading?: boolean;
}
const IntercomInclude = dynamic(() => import('components/Intercom'), {
  ssr: false
});
const MainLayout: FC<Props> = ({
  children,
  bgOverride,
  loading = false
}) => {
  const matchDownMd = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  const isDrawerOpen = useStore(s => s.SideBarStoreData.isDrawerOpen, []);
  return <>
      {loading ? <PageLoader /> : <>
          <Box sx={{
        display: 'flex'
      }}>
            {matchDownMd && <AppBar enableColorOnDark position="fixed" color="inherit" elevation={2} sx={theme => ({
          bgcolor: theme.palette.background.default,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(255, 255, 255, 0.8)'
        })}>
                <Toolbar sx={{
            p: {
              sm: '10px',
              md: '16px'
            }
          }}>
                  <Header />
                </Toolbar>
              </AppBar>}

            <Sidebar />

            <Main className={'page-content-div'} open={!isDrawerOpen} sx={{
          backgroundColor: bgOverride ? bgOverride : '#F7F5F2'
        }}>
              {children}
            </Main>
          </Box>
          {(isProduction() || USE_INTERCOM) && <IntercomInclude />}
        </>}
    </>;
};
export default MainLayout;