import { getTeacherUrl } from '@magicschool/utils/nextjs/url';

const teacherUrl = getTeacherUrl();
export const VERCEL_URL = new URL(teacherUrl).origin;

const LAYOUT = {
  main: 'main',
  noauth: 'noauth',
  minimal: 'minimal',
} as const;

export const exportableTextQuestionTools = ['text-dependent-questions', 'restorative-reflection', 'math-problem', 'math-spiral-review'];

export const exportableChoiceQuestionTools = [
  'multiple-choice-quiz-text-based',
  'sat-math-practice',
  'sat-reading-custom',
  'sat-reading-practice',
  'reading-quiz',
  'diagnostic-assessment-generator',
  'mc-assessment',
  'youtube',
  'survey-creator',
];

export const exportableSlideTools = ['presentation-generator'];

export const DEFAULT_MAX_TOKENS = 4096;

export const AUTO_JOIN_CODE_KEY = 'auto-join-code' as const;

export const GENERATION_ID_HEADER = 'x-msai-generation-id';

export default LAYOUT;
