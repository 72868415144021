import { styled } from '@mui/material/styles';
import { space } from '../../constants/space';
import { Button, type ButtonProps } from '../Button';
const ButtonWithGradient = styled(Button)(({
  theme,
  disabled
}) => ({
  background: disabled ? theme.palette.action.disabled : 'linear-gradient(to right, #5403FF, #8F30F6) !important',
  color: `${theme.palette.common.white} !important`,
  '&:hover': {
    background: `linear-gradient(${theme.palette.secondary.dark})`
  },
  padding: space(1, 1.5),
  textTransform: 'none',
  whiteSpace: 'pre-wrap'
}));
export const GradientButton = (props: ButtonProps) => <ButtonWithGradient component={props.component} variant="contained" {...props} data-sentry-element="ButtonWithGradient" data-sentry-component="GradientButton" data-sentry-source-file="GradientButton.tsx">
    {props.children}
  </ButtonWithGradient>;