// types
import type { ConfigProps } from 'types/config';

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'

export const TOOLS_PATH = '/tools';
export const S_TOOLS_PATH = '/s/tools';

const config: ConfigProps = {
  locale: 'en-us',
};

export default config;
