// TODO: get spacing from theme
import { Box } from '@magicschool/ui/Box';
import { styled } from '@mui/material/styles';
export const FlexBox = styled(Box)({
  display: 'flex'
});
export const ColumnBox = styled(FlexBox)(({
  gap = 20
}: {
  gap?: number | string;
}) => ({
  flexDirection: 'column',
  gap: gap
}));
export const RowBox = styled(FlexBox)(({
  gap = 20
}: {
  gap?: number | string;
}) => ({
  flexDirection: 'row',
  gap: gap
}));
export const CenteredBox = styled(FlexBox)({
  justifyContent: 'center',
  alignItems: 'center'
});
export const HorizontallyCenteredBox = styled(FlexBox)({
  justifyContent: 'center'
});
export const VerticallyCenteredBox = styled(FlexBox)({
  alignItems: 'center'
});