import { Role } from '@edlink/typescript';
import { isValidEmail as _isValidEmail } from '@magicschool/business-logic/users';
import { UserRole, type UserRoleType } from '@magicschool/supabase/types';

/**
 * @deprecated use @magicschool/business-logic/users/isValidEmail
 * @param string to check
 * @returns boolean
 */
export const isValidEmail = (email: string | undefined | null) => {
  return _isValidEmail(email);
};

export const MAGICSCHOOL_ROLE_TO_INTL = {
  [UserRole.teacher]: 'users.magicschool-role.teacher',
  [UserRole.student]: 'users.magicschool-role.student',
  [UserRole.org_admin]: 'users.magicschool-role.org-admin',
  [UserRole.internal]: 'users.magicschool-role.internal',
  [UserRole.superuser]: 'users.magicschool-role.superuser',
};

export const getMagicSchoolRoleIntl = (userRole: UserRoleType | null) => {
  if (!userRole) return 'users.magicschool-role.none';
  return MAGICSCHOOL_ROLE_TO_INTL[userRole] || 'users.magicschool-role.none';
};

// these used to live in business-logic/edlink but i got all kinds of weird use-client errors so.. :shrug:
export const EDLINK_ROLE_TO_INTL: Record<Role, string> = {
  [Role.Administrator]: 'users.edlink-role.administrator',
  [Role.DistrictAdministrator]: 'users.edlink-role.district-administrator',
  [Role.Teacher]: 'Teacher',
  [Role.TeachingAssistant]: 'users.edlink-role.ta',
  [Role.Staff]: 'users.edlink-role.staff',
  [Role.Aide]: 'users.edlink-role.aide',
  [Role.Observer]: 'users.edlink-role.observer',
  [Role.Student]: 'users.edlink-role.student',
  [Role.Parent]: 'users.edlink-role.parent',
  [Role.Guardian]: 'users.edlink-role.guardian',
  [Role.Designer]: 'users.edlink-role.designer',
  [Role.Member]: 'users.edlink-role.member',
};

export const getEdlinkRoleIntl = (role: Role | null): string => {
  if (!role) return 'users.edlink-role.unknown';
  return EDLINK_ROLE_TO_INTL[role] || 'users.edlink-role.unknown';
};

export const getRoleForString = (role: string): Role | null => {
  if (EDLINK_ROLE_TO_INTL[role.trim() as Role]) {
    return role.trim() as Role;
  }
  return null;
};

export const getEdlinkRolesIntl = (roles: string[] | null): string[] => {
  if (!roles) return ['users.edlink-role.unknown'];
  if (roles.length === 0) return ['users.magicschool-role.none']; // i'm reusing this.. don't think we need more "None" in the intl file
  let intls: string[] = [];
  roles.map((role) => {
    const maybeRole = getRoleForString(role);
    if (maybeRole) {
      intls.push(EDLINK_ROLE_TO_INTL[maybeRole]);
    }
  });
  return intls;
};
