import { UserRole } from '@magicschool/supabase/types';
import { space } from '@magicschool/ui/constants/space';
import { useStore } from 'features/store';
import useFeatureFlags from 'hooks/useFeatureFlags';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { memo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import type { NavItemType } from '../sidebarStore';
import NavItem from './NavItem';
const navItems: NavItemType[] = [{
  id: 'magic-admin',
  title: 'Admin',
  url: '/admin/',
  dataTestId: 'admin-nav-dropdown',
  icon: <Image width="24" height="24" src="/icons/icon-potion.svg" alt="Internal Admin drop down" />,
  target: false,
  requireAtLeastRole: UserRole.internal,
  children: [{
    id: 'admin-user-search',
    title: 'User Search',
    url: '/admin/users',
    dataTestId: 'admin-user-search',
    icon: <Image width="24" height="24" src="/tools/icon-tool-academic-content-generator.svg" alt="Link to Admin User Search" />,
    target: false,
    requireAtLeastRole: UserRole.internal
  }, {
    id: 'admin-org-search',
    title: 'Org Search',
    url: '/admin/orgs',
    dataTestId: 'admin-org-search',
    icon: <Image width="24" height="24" src="/icon-made-for-schools.svg" alt="Link to Admin Org Search" />,
    target: false,
    requireAtLeastRole: UserRole.internal
  }, {
    id: 'admin-org-moderation',
    title: 'Moderation Settings',
    url: '/admin/moderation-settings',
    dataTestId: 'admin-org-moderation',
    icon: <Image width="24" height="24" src="/tools/icon-tool-ai-resistant-assignment.svg" alt="Link to Admin Org Moderation Settings" />,
    target: false,
    requireAdminAccess: true,
    requireAtLeastRole: UserRole.internal
  }, {
    id: 'admin-tools',
    title: 'Tools',
    url: '/admin/tools',
    icon: <Image style={{
      marginRight: space(1.5)
    }} width="24" height="24" src="/icons/icon-sidebar-magic-tools.svg" alt="Admin Tools" />,
    target: false,
    requireAdminAccess: true,
    requireAtLeastRole: UserRole.internal
  }, {
    id: 'admin-tool-languages',
    title: 'Translations',
    url: '/admin/translations',
    icon: <Image style={{
      marginRight: space(1.5)
    }} width="24" height="24" src="/tools/icon-tool-quote-of-the-day.svg" alt="Tool Quote of the Day Icon" />,
    target: false,
    requireAdminAccess: true,
    requireAtLeastRole: UserRole.internal
  }, {
    id: 'admin-system-configuration',
    title: 'System Messages',
    url: '/admin/system',
    dataTestId: 'system-messages',
    icon: <Image width="24" height="24" src="/tools/icon-tool-text-dependent-questions.svg" alt="Link to Configure System Messages" />,
    target: false,
    requireAdminAccess: true,
    requireAtLeastRole: UserRole.internal
  }]
}, {
  id: 'org-admin',
  title: <FormattedMessage id="navigation.org-home" />,
  url: '/o/',
  icon: <Image width="24" height="24" src="/tools/icon-tool-conceptual.svg" alt="Link to Organization Dashboard" />,
  target: false,
  requireAtLeastRole: UserRole.org_admin,
  children: [{
    id: 'org-users',
    parentId: 'org-admin',
    title: <FormattedMessage id="navigation.org-users" />,
    icon: <Image width="24" height="24" src="/tools/icon-tool-group-work.svg" alt="Users" />,
    url: '/o/users',
    target: false,
    requireAtLeastRole: UserRole.org_admin
  }, {
    id: 'org-dashboard',
    parentId: 'org-admin',
    title: <FormattedMessage id="navigation.org-reporting-dashboard" />,
    icon: <Image width="24" height="24" src="/tools/icon-tool-data-table-analysis.svg" alt="Dashboard" />,
    url: '/o/dashboard',
    target: false,
    requireAtLeastRole: UserRole.org_admin
  }, {
    id: 'org-rooms',
    parentId: 'org-admin',
    title: <FormattedMessage id="navigation.org-rooms" />,
    icon: <Image width="24" height="24" src="/tools/icon-tool-choice-board.svg" alt="Rooms" />,
    url: '/o/rooms',
    target: false,
    requireAtLeastRole: UserRole.org_admin,
    requireMagicStudent: true
  }]
}, {
  id: 'tools',
  title: <FormattedMessage id="magic-tools" />,
  url: '/tools',
  altUrl: '/',
  icon: <Image width="24" height="24" src="/icons/icon-sidebar-magic-tools.svg" alt="Magic Tools" />,
  target: false
}, {
  id: 'raina',
  title: <FormattedMessage id="raina-ai-chatbot" />,
  url: '/raina',
  icon: <Image width="24" height="24" src="/icons/icon-sidebar-raina.svg" alt="Raina (Chatbot)" />,
  target: false
}, {
  id: 'history',
  title: <FormattedMessage id="output-history" />,
  url: '/history',
  icon: <Image width="24" height="24" src="/icons/icon-sidebar-output-history.svg" alt="Output History" />,
  target: false
}];
const MenuList = () => {
  const {
    userRole,
    magicStudentEnabled,
    loading,
    adminAccess
  } = useFeatureFlags();
  const pathname = usePathname();
  const {
    initialize,
    filteredNavItems
  } = useStore(({
    SideBarStoreData: s
  }) => ({
    initialize: s.initialize,
    filteredNavItems: s.navItems
  }), []);
  useEffect(() => {
    if (pathname && !loading) {
      initialize(navItems, userRole, magicStudentEnabled, adminAccess);
    }
  }, [userRole, magicStudentEnabled, pathname, initialize, loading, adminAccess]);
  return <>
      {filteredNavItems.map(menu => <NavItem key={menu.id} item={menu} />)}
    </>;
};
export default memo(MenuList);